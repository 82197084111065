.features {
    .content {
        margin-top: 50px;
        max-width: 1300px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 30px;
        justify-items: center;
        justify-content: center;
        align-items: start;
        @include respond-to("tablet") {
            grid-template-columns: unset;
            flex-direction: column;
        }
    }
    &-box {
        width: 100%;
        transition: all 300ms ease;
        opacity: 0.3;
        background: rgba(38, 171, 225, 0.1);
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        position: relative;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 20px;
        margin: 20px;
        &-icon {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background: rgba(166, 214, 255, 0.1);
            border: 3px solid #A6D6FF;
            box-sizing: border-box;
            box-shadow: 0px 0px 10px rgba(156, 142, 255, 0.5);
            box-sizing: border-box;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            img {
                position: absolute;
                transform: translate(-50%, -50%);        
                top: 50%;
                left: 50%;  
            }
        }
        h2 {
            transition: all 400ms ease;
            font-weight: bold;
            font-size: 1.55em;
            letter-spacing: 1.92308px;
        }
        p {
            margin: 25px auto 0 auto;
            width: 90%;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 1.25em;
            letter-spacing: 1px;
        }
        &:hover {
            opacity: 1;
            h2 {
                color: #A6D6FF;
                text-shadow: 0px 0px 12px rgba(156, 142, 255, 0.25);
            }
        }
    }
}