.success {
    .center {
        min-height: calc(80vh);
    }
    .content {
        @include respond-to("tablet") {
            display: block;
        }
        @include respond-to("mobile") {
            display: block;
        }
        max-width: 1250px;
        .column {
            &-one {
                width: 60%;
                margin-bottom: 20px;
                img {
                    max-width: 701px;
                    width: 100%;
                    margin: auto;
                }
                @include respond-to("tablet") {
                    display: block;
                    width: 100%;
                    padding: 20px;
                }
                @include respond-to("mobile") {
                    display: block;
                    width: 100%;
                    padding: 20px;
                }
            }
            &-two {
                padding: 5% 0;
                text-align: left;
                margin-left: 5%;
                width: 35%;
                p {
                    display: block;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    margin-bottom: 40px;
                }
                a {
                    display: block;
                    span {
                        margin: 7px 0;
                        font-weight: bold;
                        font-size: 15px;
                        line-height: 18px;
                        letter-spacing: normal;
                    }
                    img {
                        float: right;
                        @include respond-to("mobile") {
                            display: none;
                        }
                    }
                    background: rgba(15, 255, 63, 0.05);
                    border: 3px solid $accent-one;
                    box-sizing: border-box;
                    border-radius: 30px;
                    padding: 21px 28px;
                    transition: box-shadow 200ms ease-in-out, transform 100ms ease-in-out;
                    &:hover {
                        box-shadow: 0px 0px 20px $accent-one;
                    }
                    &:active {
                        transform: scale(0.95);
                    }
                }
                @include respond-to("tablet") {
                    display: block;
                    width: 100%;
                    padding: 20px;
                }
                @include respond-to("mobile") {
                    display: block;
                    width: 100%;
                    padding: 20px;
                }
            }
        }
    }
}

body.fourOhFour {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.homeLink {
    margin-top: 15px;
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    background: rgba(15, 255, 63, 0.05);
    border: 3px solid $accent-one;
    border-radius: 10px;
    padding: 13px 16px;
    transition: box-shadow 200ms ease-in-out, transform 100ms ease-in-out;
    &:hover {
        box-shadow: 0 0 10px $accent-one;
    }
    &:active {
        transform: scale(0.95);
    }
}