.contact {
    .content {
        width: 100%;
        max-width: 1150px;
        .form {
            padding: 40px 123px;
            margin: 0 auto;
            width: 90%;
            max-width: 956px;
            background: url("./images/contact-form.png") rgba(38, 171, 225, 0.05);
            mix-blend-mode: normal;
            border-radius: 30px;
            @include respond-to("desktop") {
                padding: 40px;
            }
            @include respond-to("tablet") {
                padding: 20px;
            }
            @include respond-to("mobile") {
                padding: 20px;
            } 
        }
        span {
            margin: 0 10px;
            display: inline-block;
            width: fit-content;
            &:nth-of-type(3) {
                margin-top: 32px;
            }
            @include respond-to("tablet") {
                margin: 10px;
            }
            @include respond-to("mobile") {
                margin: 10px 0;
            } 
        }
        label {
            display: block;
            margin: 0 0 10px 0;
        }
        input, textarea {
            font-size: 16px;
            line-height: 20px;
            padding: 20px;
            outline: none;
            background: rgba(38, 171, 225, 0.05);
            border: 1.5px solid rgba(38, 171, 225, 0.5);
            box-sizing: border-box;
            border-radius: 30px;
        }

        input {
            width: 330px;
            height: 60px;
            max-width: 100%;
        }
        textarea {
            width: 680px;
            min-height: 250px;
            resize: vertical;
            max-width: 100%;
        }
        button {
            outline: none;
            display: block;
            margin: 37px auto 0 auto;
            background: rgba(15, 255, 63, 0.05);
            border: 3px solid $accent-one;
            box-sizing: border-box;
            border-radius: 30px;
            padding: 22px 54px;
            text-align: left;
            p {
                float: left;
                padding: 6px 0 8px 0;
                width: fit-content;
                font-weight: bold;
                font-size: 15px;
                line-height: 18px;
            }
            img {
                float: right;
                @include respond-to("mobile") {
                    display: none;
                }
            }
            &:hover {
                box-shadow: 0px 0px 20px $accent-one;
            }
            &:active {
                transform: scale(0.95);
            }
        }
    }
}