* {
  margin: 0;
  padding: 0;
  color: white;
  max-width: 100%;
  user-select: none;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
}

html,
body {
  height: 100%;
}

body {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $main-bg;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

.icon-name {
  height: 30px;
  width: fit-content;
  img {
      display: inline-block;
      margin-right: 15px;
  }
  p {
      margin: 3px 0;
      float: right;
      font-weight: 600;
      line-height: 24px;
  }
}
section {
  height: fit-content;
  min-height: 100vh;
  margin-bottom: 5vh;
  h1 {
    margin: 0 30px 0 80px;
    font-weight: bold;
    font-size: 5.5rem;
    line-height: 66px;
    display: inline-block;
    @include respond-to("tablet") {
      margin: 0 30px;
      font-size: 4rem;
    }
    @include respond-to("mobile") {
      margin: 0 20px;
      font-size: 3rem;
    }
  }
  .icon-name {
    margin: 18px 0;
    display: inline-block;
  }
}
.content {  
  transform: scale(1);
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-flow: row nowrap;
}
.center {
  height: fit-content, available;
  min-height: 50vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.column {
  &-1, &-2 {
    display: flex;
  }
}

@media (min-width: 2000px) {
  .content {
    transform: scale(1.5);
  }
}
@media (min-width: 2800px) {
  .content {
    transform: scale(2);
  }
}