footer {
    background: rgba(38, 171, 225, 0.1);
    width: 100%;
    padding: 50px;
    .top, .bottom {
        display: block;
        padding: 20px;
    }
    .top {
        border-bottom: 1px solid #26ABE1;;
        .icon-name {
            display: inline-block;
        }
        .copyright {
            margin: 6px 0;
            float: right;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 1.15385px;
            @include respond-to("tablet") {
                float: none;
                display: block;
            }
            @include respond-to("mobile") {
                padding: 20px 0;
            }  
        }
    }
    .bottom {
        margin-top: 20px;
        text-align: center;
        a {
            color: #26ABE1;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}