header {
    .icon-name {
        margin: 34px 0 0 55px;
        @include respond-to("mobile") {
            margin: 20px 0 0 20px;
        }
    }
    .center {
        min-height: calc(100vh - 64px);
    }
    .content {
        max-width: 1250px;
        @include respond-to("mobile") {
            max-width: 80%;
            max-width: 100%;
            flex-direction: column;
        }
        h1 {
            font-weight: bold;
            font-size: clamp(36px, 5vw, 65px);
        }
        h2 {
            font-weight: 600;
            font-size: clamp(18px, 5vw, 30px);
            margin-bottom: 25px;
        }
        .column {
            &-one {
                width: 30%;
                text-align: center;
                @include respond-to("tablet") {
                    width: 100%;
                    padding: 20px;
                }
                @include respond-to("mobile") {
                    width: 100%;
                    padding: 20px;
                    a {
                        img {
                            display: none;
                        }
                    }
                }
                a {
                    margin: 50px auto;
                    display: block;
                    box-sizing: border-box;
                    width: 317px;
                    height: 89px;
                    padding: 15px 29px;
                    background: #FFFFFF;
                    border-radius: 30px;
                    transition: box-shadow 200ms ease-in-out, transform 100ms ease-in-out;
                    &:hover {
                        box-shadow: 0px 0px 20px $accent-one;
                    }
                    &:active {
                        transform: scale(0.95);
                    }
                    img, div {
                        float: left;
                    }
                    div {
                        margin: 9px;
                    }
                    p {
                        color: black;
                        text-align: left;
                        &.purchase {
                            font-weight: bold;
                            font-size: 15px;
                            line-height: 18px;
                            color: #000000;
                        }
                        &.price {
                            font-size: 15px;
                            line-height: 18px;
                            color: #808080;
                        }
                    }
                }
            }
            &-two {
                margin-left: 10%;
                width: 60%;
                img {
                    max-width: 701px;
                    width: 100%;
                    margin: auto;
                }
                @include respond-to("mobile") {
                    display: none;
                }
                @include respond-to("tablet") {
                    display: none;
                }
            }
        }
    }
}